header {
  font-family: "Quicksand", sans-serif;
  width: 100vw;
  height: 17.5vh;
  text-align: center;
  text-decoration: none;
  padding: 8vh 0vw 0vh 0vw;
  font-family: "Open Sans", sans-serif;
  font-weight: none;
  font-size: 5vh;
  position: sticky;
  top: 0;
  animation: fadeInAnimation ease 1.2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
footer {
  width: 100vw;
  height: 10vh;
  background: #000000;
  color: #ffffff;
  position: relative;
  bottom: 0;
  position: sticky;
  text-align: center;
  animation: fadeInAnimation ease 1.1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
ul li div a {
  text-align: left;
  vertical-align: top;
  margin: 0vh 0vw 0vh 0vw;
}
li {
  text-decoration: none;
  display: inline-block;
  padding: 0;
  margin: 2vw;
}

#icon {
  position: relative;
  top: 0.5vh;
  /* left: 0.4vh; */
}
#spans {
  float: right;
}

.homepage {
  font-family: "Quicksand", sans-serif;
  text-align: center;
  vertical-align: middle;
  font-size: 2.2vh;
  width: 100vw;
  height: 67.5vh;
  padding: 10vh 0vw 0vh 0vw;
  animation: fadeInAnimation ease 1.4s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
#personal {
  height: 42vh;
  width: 42vh;
  border-radius: 10px;
  background: #f2f2f2;
  justify-content: center;
  align-items: center;
  display: block;
  margin: 2.5vh 0vw 0vh 1.5vw;
}
#professional {
  height: 42vh;
  width: 42vh;
  border-radius: 10px;
  background: #d3d3d3;
  justify-content: center;
  align-items: center;
  display: block;
  margin: 2.5vh 1.5vw 0vh 0vw;
}
.cont {
  height: 67.5vh;
  width: 100vw;
  display: flex;
  text-align: center;
  justify-content: center;
}
#title {
  display: inline-block;
  margin: 0vh 0vw 0vh 0vw;
}
#select1 {
  display: inline-block;
  margin: 0vh 1vw 0.5vh 1.5vw;
}
input[type="radio"] {
  margin-top: -1px;
  margin: 0 1vw 0 1vw;
  display: inline;
  animation: fadeInAnimation ease 1.2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
#radios {
  margin: 1vh 0vw 2vh 0vw;
  font-size: 3vw;
  display: block;
}

#t {
  font-size: 2.25vh;
  padding: 2vh 0vw 0vh 2.5vw;
}
#input {
  display: block;
  font-size: 2vh;
  padding: 0vh 0vw 0vh 2vw;
}
#poem {
  height: 43vh;
  width: 52vw;
  text-align: center;
  display: block;
}

/*//////////////////////////////////////////////////////////////*/
#ppp {
  display: flex;
}
.prof {
  height: 67.5vh;
}
#personal:hover {
  cursor: pointer;
  color: #000000;
  text-decoration: none;
  border-color: #000000;
}
.work a {
  color: #808080;
}
.work a img {
  width: 33vh;
  height: 33vh;
  border-radius: 50%;
  align-items: center;
  display: block;
}
#professional:hover {
  cursor: pointer;
  color: #000000;
  text-decoration: none;
  border-color: #000000;
}
div a:hover {
  text-decoration: none;
  color: #000000;
}
.nav {
  height: 4.5vh;
  width: 100%;
  background: lightgrey;
  border-top: 1px solid #000000;
  border-bottom: 1px solid #000000;
  animation: fadeInAnimation ease 1.2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
.nav ul {
  text-align: center;
  position: relative;
  display: inline-block;
  margin: auto;
}
.nav ul li {
  display: inline-block;
  margin: 0;
  padding: 0 20px 0 20px;
}
.nav ul li a {
  display: block;
  line-height: 10px;
  text-align: center;
  text-decoration: none;
}

.navbar {
  background: lightgrey;
  width: 100%;
  height: 4.5vh;
  align-items: center;
  border-top: 1px solid #000000;
  border-bottom: 1px solid #000000;
}
.navbar a {
  display: block;
  color: #000000;
  margin-left: 1.5vw;
  line-height: 0.25vh;
  text-align: center;
  font-size: 2.25vh;
}
.nav-menu {
  background: gray;
  height: 63vh;
  width: 11vw;
  display: flex;
  justify-content: left;
  position: relative;
  top: 0;
  left: -100%;
  z-index: 1;
  transition: 850ms;
}
.nav-menu.active {
  left: 0;
  z-index: 1;
  transition: 350ms;
  display: flex;
}
.nav-menu-items {
  background: #060b2b;
  display: block;
  height: 63vh;
  width: 17.5vw;
  z-index: 1;
}
.nav-menu-items > li {
  background: #060b2b;
  width: 17.5vw;
  padding: 0;
  margin: 0;
}
.nav-text {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 2vh;
  padding: 0vw 0vw 0vh 4vw;
  margin: 0vw 0vw 0vh 4vw;
  list-style: none;
}
.nav-text a {
  text-decoration: none;
  color: #ffffff;
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0px 16px;
  list-style: none;
  height: 60px;
  cursor: pointer;
}
.nav-text a:hover {
  color: #ffffff;
  background-color: #022083;
  border-color: #000000;
  cursor: pointer;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@media screen and (min-width: 320px) {
  /* smartphones, iPhone, portrait 480x320 phones */
  .nav-menu-items {
    width: 20vw;
  }
}
@media screen and (min-width: 481px) {
  /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */
  .nav-menu-items {
    width: 20vw;
  }
}
@media screen and (min-width: 641px) {
  /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
  .nav-menu-items {
    width: 20vw;
  }
}
@media screen and (min-width: 961px) {
  /* tablet, landscape iPad, lo-res laptops ands desktops */
  #title {
    display: inline-block;
    margin: 0vh 0vw 0vh 0vw;
  }
  #select1 {
    display: inline-block;
    margin: 0vh 1vw 0.5vh 1.5vw;
    font-size: 1vw;
  }
  input[type="radio"] {
    margin-top: -1px;
    margin: 0 0.5vw 0 0.5vw;
    display: inline;
    animation: fadeInAnimation ease 1.2s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }
  #poem {
    height: 45vh;
    width: 55vw;
    text-align: center;
    display: block;
  }
  #radios {
    margin: 0vh vw 2vh 0vw;
    font-size: 1vw;
    display: block;
  }

  #book {
    height: 63vh;
    width: 50vw;
    font-size: 2vh;
    background: goldenrod;
    display: inline;
    top: 0;
  }
  #x {
    height: 63vh;
    width: 50vw;
    background: gold;
    display: inline;
  }
  #t {
    font-size: 1.25vw;
  }
  #input {
    display: block;
    font-size: 2vh;
    padding: 0vh 0vw 0vh 2vw;
  }
  #poem {
    height: 42vh;
    width: 16.5vw;
    text-align: center;
    display: block;
  }
}
@media screen and (min-width: 1025px) {
  /* big landscape tablets, laptops, and desktops */
}
@media screen and (min-width: 1281px) {
  /* hi-res laptops and desktops */
}
